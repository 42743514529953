@import '../variables';

$size: 16px;

.lmr-checkbox {
  position: relative;
  height: $size;
  width: $size;
  min-width: $size;
  min-height: $size;

  label {
    background-color: #00000000;
    border: 1px solid $primary;
    border-radius: 4px;
    cursor: pointer;
    height: $size;
    left: 0;
    position: absolute;
    width: $size;
    margin: 0 !important;
  }

  label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    left: 3px;
    opacity: 0;
    position: absolute;
    width: 8px;
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  label:focus {
    outline: none !important;
    box-shadow: 0 0 1pt 1pt $primary;
  }

  input[type='checkbox']:checked + label {
    background-color: $primary;
    border-color: $primary;
  }

  input[type='checkbox']:indeterminate + label {
    background-color: $primary;
    border-color: $primary;
  }

  input[type='checkbox']:checked + label:after {
    height: 5px;
    opacity: 1;
    top: 3px;
    transform: rotate(-45deg);
  }

  input[type='checkbox']:indeterminate + label:after {
    opacity: 1;
    height: 0;
    top: 6px;
  }
}
