.btn-success,
.btn-info,
.btn-primary,
.btn-danger {
  color: #fff !important;
}

.form-check > * {
  cursor: pointer;
}

select.form-control {
  appearance: auto;
}

.input-group-multiline input {
  border-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-multiline .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-multiline .input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
