@import '../variables';

.cursor-inherit {
  cursor: inherit !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;

  &.text-primary:hover {
    color: $primary-hover !important;
  }

  &.text-secondary:hover {
    color: $secondary-hover !important;
  }

  &.text-warning:hover {
    color: $warning-hover !important;
  }

  &.text-danger:hover {
    color: $danger-hover !important;
  }

  &.text-success:hover {
    color: $success-hover !important;
  }
}

.cursor-text {
  cursor: text !important;
}
