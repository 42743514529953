@import '../variables';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';

.tooltip {
  z-index: 1120;
}

.bs-datepicker-container div.bs-datepicker-head {
  background-color: $primary;
}

.bs-datepicker-container div.bs-datepicker-body {
  font-family: LatoWeb;

  table {
    td[role='gridcell'] {
      color: $primary;
    }

    tr {
      td.week {
        span {
          color: $gray-300;
        }
      }

      td:nth-of-type(--first-day-of-week) {
        span:not(.is-other-month) {
          color: $danger;
        }
      }
    }
  }
}

.tooltip-inner {
  border: 1px solid $border-color;
  background-color: $white;
  color: $primary;
  box-shadow: $shadow-default;
}

.bigger-tooltip .tooltip-inner {
  max-width: 400px;
}

.bs-tooltip-bottom .tooltip-arrow.arrow:before {
  border-bottom-color: $gray-500;
}

.bs-tooltip-left .tooltip-arrow.arrow:before {
  border-left-color: $gray-500;
}

.bs-tooltip-right .tooltip-arrow.arrow:before {
  border-right-color: $gray-500;
}

.bs-tooltip-top .tooltip-arrow.arrow:before {
  border-top-color: $gray-500;
}
