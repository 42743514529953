@import '../variables';

.lmr-accordion {
  border: 0;

  .panel-heading {
    border-bottom: 0;
    user-select: none;
    padding: 0.5rem;
  }

  .panel-body {
    padding: 0;
    margin: 0.5rem 0;
  }
}
