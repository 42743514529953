@import '../variables';

.fc-toolbar {
  .fc-button-primary {
    background: $primary !important;
  }

  .fc-button br {
    display: none;
  }

  .fc-toolbar-title {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.fc .fc-head .fc-widget-header {
  margin-top: 15px;
}

.fc .fc-widget-header {
  border: 0;
}

.fc .fc-view-harness.fc-view-harness-passive {
  overflow: auto;
}

.fc th {
  text-align: right !important;
  margin-right: 0.2rem;
  border-bottom-width: 1px;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  padding-bottom: 3px;
}

.fc .fc-scrollgrid {
  border: 0 !important;
}

.fc td:last-child {
  border-right: 0;
}

.fc .fc-day-today {
  background: transparent !important;
}

.fc .fc-scrollgrid td:first-child {
  border-bottom: 0 !important;
}

.fc .fc-daygrid-day-top .fc-daygrid-day-number {
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.fc .fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  border: 2px solid $danger;
  background-color: $danger;
  color: white;
  box-sizing: content-box;
  border-radius: 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  padding: 0.1rem;
  align-items: center;
  justify-content: center;
}
