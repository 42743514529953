@import '../variables';

.toast-info {
  background-color: $info !important;
}

.toast-error {
  background-color: $danger !important;
}

.toast-warning {
  background-color: $warning !important;
}

.toast-success {
  background-color: $success !important;
}

.toast-container.toast-top-center {
  width: 100%;
}

.toast-container .ngx-toastr {
  box-shadow: $shadow-up;

  &:hover {
    box-shadow: $shadow-up;
  }
}
