// Lumeer 4.1.1
// Bootswatch

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #253746 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74c3c !default;
$orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #00b388 !default;
$teal: #20c997 !default;
$cyan: #3498db !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$text-light: $gray-400 !default;
$dark: $gray-700 !default;

$success-light: lighten($success, 50%);

$body-color: $primary;

$primary-hover: lighten($primary, 15%);
$secondary-hover: darken($secondary, 15%);
$success-hover: darken($success, 15%);
$info-hover: darken($info, 15%);
$warning-hover: darken($warning, 15%);
$danger-hover: darken($danger, 15%);
$danger-light: lighten($danger, 30%);
$light-hover: darken($light, 15%);
$dark-hover: darken($dark, 15%);

$table-border-color: $gray-200;
$table-min-row-height: 34px;
$table-outline-selected: rgba(37, 55, 70, 0.9); // $primary

// Links

$link-color: $success;

// Fonts

$font-family-sans-serif:
  'LatoWeb',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol' !default;

$font-size-base: 0.9375rem !default;

$h1-font-size: 3rem !default;
$h2-font-size: 2.5rem !default;
$h3-font-size: 2rem !default;

// Tables

$table-accent-bg: $gray-200;
$table-cell-padding-md: 0.5rem !default;

// Dropdowns

$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-link-color: $primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: $light;
$dropdown-link-active-color: $white;
$dropdown-link-active-bg: $primary;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-200;

// Navbar

$navbar-padding-y: 1rem !default;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $secondary;

// Pagination

$pagination-color: $white;
$pagination-bg: $primary;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;

$pagination-hover-color: $white;
$pagination-hover-bg: $primary-hover;
$pagination-hover-border-color: transparent !default;

$pagination-active-bg: $pagination-hover-bg;
$pagination-active-border-color: transparent !default;

$pagination-disabled-color: $gray-200;
$pagination-disabled-bg: $primary;
$pagination-disabled-border-color: transparent !default;

// Progress bars

$progress-height: 0.625rem !default;
$progress-font-size: 0.625rem !default;

// List group

$list-group-hover-bg: $gray-200;

$list-group-disabled-bg: $gray-200;

// Close

$close-color: $white;
$close-text-shadow: none !default;

// Application-specific variables

$border-color: $gray-400;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

$z-index-help: 1001;
$z-index-selected: 100;
$z-index-hovered: 90;

$shadow-default: 0 1px 5px 1px rgba(0, 0, 0, 0.2); // default shadow
$shadow-light: 0 1px 3px 1px rgba(0, 0, 0, 0.1); // default shadow
$shadow-up: 0 4px 8px 3px rgba(0, 0, 0, 0.11); // blurred shadow for 'higher' elements
$shadow-up-up: 0 6px 12px 5px rgba(0, 0, 0, 0.11); // blurred shadow for 'higher' elements
$shadow-down:
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12); // strong down shadow
$shadow-none: 0 0 0 0 rgba(0, 0, 0, 0); // invisible shadow for transitions

$post-it-width: 9rem;
$post-it-name-collapsed: 1.85rem;
$post-it-name-expanded: 300px;

$animation-duration: 0.4s ease;
$animation-duration-slow: 0.5s ease;
$animation-color-duration: 0.25s ease;
